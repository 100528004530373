import React from "react"
import classes from "./boxedItem.module.scss"

const boxedItem = (props) => {
  return (
    <div style={props.style} className={[classes.BoxedItem,props.className].join(" ")}>
      {props.children}
    </div>
  )
}

export default boxedItem

import React, { Fragment } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import translationHelper from "../i18n/helper"
import Container from "react-bootstrap/Container"
import ServiceCardBox from "../components/Services/ServicesGrid/ServiceCardBox/ServiceCardBox"
import BoxedItem from "../components/Misc/BoxedItem/boxedItem"
import SectionSingleVerticalSeperator
  from "../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import BackgroundImage from "gatsby-background-image"
import SectionBox from "../components/Services/SectionBox/sectionBox"
import GradientBtn from "../components/Misc/GradientButton/gradientButton"



class SingleService extends React.Component {
  render() {

    const service = this.props.data.wordpressPage
    const { darkBlueBgImage } = this.props.data
    const { locale } = this.props.pageContext
    return (
      <Layout locale={locale} path={this.props.path}>
        <SEO
          title={service.title}
          lang={this.props.pageContext.locale}
          description={service.yoast_meta.yoast_wpseo_metadesc ? service.yoast_meta.yoast_wpseo_metadesc : null}
          image={service.yoast_meta.yoast_wpseo_facebook_image ? service.yoast_meta.yoast_wpseo_facebook_image : null}
          meta={[{'keywords':service.title}]}
        />

        {/* Image and Detailed Description Section */}
        <Container>
          <Row>
            <Col md={12}>
              <EmptySpaceSeperator pixels={40}/>

              <SectionTitleSeperator h1Override={true} hideTop={true} title={parse(service.title)}/>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Row>
                <ServiceCardBox
                  alignCenter={true}
                  sm={12}
                  md={3}
                  title={parse(service.title)}
                  fluid={service.featured_media.localFile.childImageSharp.fluid}
                  titleStyle="gradient"
                />
                <Col md={9}>
                  <BoxedItem className="eqHeightGlobal p-4 colorMidGray aColorMain">
                    {service.acf.detailed_description ? parse(service.acf.detailed_description) : null}
                  </BoxedItem>
                </Col>
                <EmptySpaceSeperator/>
              </Row>
              <EmptySpaceSeperator/>
              <SectionSingleVerticalSeperator/>

            </Col>
          </Row>
        </Container>

        {/* Dark BG Full Width Section */}
        {

          service.acf.dark_bg_section != null && service.acf.dark_bg_section.title != null && service.acf.dark_bg_section.text != null && (
            <Fragment>
              <Container className="p-0" fluid={true}>
                <Row noGutters={true} className="fullWidthBg">
                  <BackgroundImage width="100%" fluid={darkBlueBgImage.childImageSharp.fluid} backgroundColor="#1f2732">
                    <SectionSingleVerticalSeperator color="#fff"/>
                    <EmptySpaceSeperator/>
                    <Container>
                      <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                          {
                            service.acf.dark_bg_section.title != null ? <h2
                                className="textShadowed text-center mainColor font-weight-bold h2GlobalLHeight">{service.acf.dark_bg_section.title}</h2>
                              : null
                          }
                          <EmptySpaceSeperator/>
                          {
                            service.acf.dark_bg_section.text !== null ? <h2
                              className="textShadowed text-center colorLight font-weight-bold h2GlobalLHeight">{service.acf.dark_bg_section.text}</h2> : null
                          }

                        </Col>
                      </Row>
                    </Container>
                    <EmptySpaceSeperator/>
                    <SectionSingleVerticalSeperator color="#fff"/>


                  </BackgroundImage>

                </Row>
              </Container>
              <Container>
                <Row>
                  <Col md={12}>
                    <SectionSingleVerticalSeperator/>
                    <EmptySpaceSeperator/>
                  </Col>

                </Row>
              </Container>
            </Fragment>

          )

        }

        {/* Section Boxes */}
        {
          service.acf.section_boxes && service.acf.section_boxes.length > 0 ?
            <Container>


              <Row>
                {
                  service.acf.section_boxes.map((item, index) => {
                    return <SectionBox
                      key={index}
                      md={item.grid_column}
                      title={item.title}
                      description={item.content}
                      add_seperator={item.add_seperator ? 1 : 0}
                      type={item.tmpl_type}
                      className="h4-med mb-4"
                    />
                  })
                }
              </Row>
            </Container>

            : null
        }

        {/* Contact Btn*/}

        <Container>
          <Row>
            <Col md={12}>
              <GradientBtn className="p-0" linkStyle={{ display: "block", borderRadius: "4px" }}
                           url={translationHelper[locale]["contactPageUrl"]}
                           btnText={translationHelper[locale]["contact"]}/>
            </Col>
          </Row>
          <Row>
            <EmptySpaceSeperator pixels={40}/>

          </Row>

        </Container>

      </Layout>
    )
  }
}

SingleService.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default SingleService

export const pageQuery = graphql`
    query($id: Int!) {
        darkBlueBgImage: file(relativePath: {eq: "bg-images/section-darkblue.png"}) {
            childImageSharp {
                fluid(maxWidth:4200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        wordpressPage(wordpress_id: { eq: $id }) {
            wordpress_id
            title
            content
            date
            excerpt
            featured_media {
                localFile {
                    childImageSharp {
                        fluid{
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
            acf {
                section_boxes {
                    title
                    content
                    grid_column
                    add_seperator:add_seperator_after
                    tmpl_type:type
                }
                detailed_description
                short_description
                dark_bg_section {
                    title:highlighted_text
                    text:text
                }
            }
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_facebook_title
                yoast_wpseo_canonical
            }

        }

    }
`

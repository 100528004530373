import React, { Fragment } from "react"
import PropTypes from "prop-types"
import SectionBoxDefault from "./SectionBoxDefault/sectionBoxDefault"
import SectionSingleVerticalSeperator from "../../Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import EmptySpaceSeperator from "../../Misc/EmptySpaceSeperator/emptySpaceSeperator"
import Col from "react-bootstrap/Col"
import classes from "./sectionBox.module.scss"
import Card from "react-bootstrap/Card"

const sectionBox = (props) => {

  let seperator = null

  if (props.add_seperator === 1) {
    seperator = <Col md={12}>
      {props.type === "red" ? <EmptySpaceSeperator/> : null}
      <SectionSingleVerticalSeperator/>
      <EmptySpaceSeperator/>
    </Col>
  }
  switch (props.type) {

    case ("default"):
      return <Fragment>
        <SectionBoxDefault {...props}/>
        {seperator}
      </Fragment>

    case ("iframe"):
      return <Fragment>
        <Col md={12}>
          <EmptySpaceSeperator/>
          <iframe title={props.title} src={props.description} width="100%" height="600"
                  style={{ marginTop: "20px", marginBottom: "20px" }}/>

          {seperator}
        </Col>
      </Fragment>

    case ("red"):
      return <Fragment>
        <Col className={classes.SectionRedWrapper} md={props.md}>
          <Card>
            <Card.Body className={classes.RedBody}>
              <h4 className="colorLight textShadowed">{props.title}</h4>

            </Card.Body>
          </Card>

        </Col>
        {seperator}

      </Fragment>

    default:
      return <Fragment>
        <SectionBoxDefault {...props}/>
        {seperator}
      </Fragment>


  }

}

export default sectionBox

sectionBox.propTypes = {
  type: PropTypes.oneOf(["default", "red", "iframe", "no_title"]).isRequired,
  add_seperator: PropTypes.oneOf([0, 1]),

}
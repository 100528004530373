import React from "react"
import ServiceCardBox from "../../ServicesGrid/ServiceCardBox/ServiceCardBox"
import parse from "html-react-parser"

const sectionBoxDefault = (props) => {
  return (
    <ServiceCardBox
      md={props.md}
      title={props.title}
      description={
        props.description ?
          <p>{parse(props.description)}</p>
          : null
      }
      className={props.className}
      titleStyle={props.titleStyle}
    />
  )
}

export default sectionBoxDefault

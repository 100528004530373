import React from "react"
import classes from "./sectionSingleVerticalSeperator.module.scss"

const sectionSingleVerticalSeperator = (props) => {
  const styleColor = props.color ? { borderColor: props.color} : null

  return (
    <div className={props.classes}>
      <div className={classes.Wrapper}>
        <div style={styleColor} className={classes.VerticalLine}/>
      </div>
    </div>
  )
}
sectionSingleVerticalSeperator.defaultProps = {
  color: null,
}
export default sectionSingleVerticalSeperator

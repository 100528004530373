import React, { Fragment } from "react"
import classes from "./serviceCardboxTitle.module.scss"
import PropTypes from "prop-types"
import GradientText from "../../../../Misc/GradientText/gradientText"
import EmptySpaceSeperator from "../../../../Misc/EmptySpaceSeperator/emptySpaceSeperator"

const serviceCardBoxTitle = (props) => {
  return (
    <div className={classes.titleWrapper}>
      {props.titleStyle === "dashed" ?
        <Fragment>
          <span className={classes.seperatorHolder}>
        <span className={classes.seperatorLine}/>
      </span>
          <h4 className={classes.seperatorTitle}>{props.title}</h4>

          <span className={classes.seperatorHolder}>
        <span className={classes.seperatorLine}/>
         </span>
          <EmptySpaceSeperator  pixels={30} />
        </Fragment>
        : <GradientText className="text-center w-100 f-s-20">{props.title}</GradientText>

      }
    </div>
  )
}

serviceCardBoxTitle.defaultProps = {
  titleStyle: "dashed",
}
serviceCardBoxTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleStyle: PropTypes.oneOf(["dashed", "gradient"]),


}
export default serviceCardBoxTitle

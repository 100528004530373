import React from "react"
import classes from "./SectionTitleSeperator.module.scss"
import PropTypes from "prop-types"


const SectionTitleSeperator = (props) => {

  let noMgStyle = {}

  if (props.noMgTop) {
    noMgStyle = {
      marginTop: "0px",
    }
  }

  if (props.noMgBottom) {
    noMgStyle = {
      marginBottom: "0px",
      ...noMgStyle,
    }
  }

  return (
    <div className={[classes.titleSeperatorWrapper, props.className].join(" ")}>
      {!props.hideTop ? <div style={noMgStyle} className={classes.verticalLine}/> : null}
      {/*<Fade left cascade>*/}
      {
        props.h1Override? <h1>{props.title}</h1> :  <h2>{props.title}</h2>

      }
      {/*</Fade>*/}
      {!props.hideBot ? <div  className={classes.verticalLine}/> : null}
    </div>
  )
}

SectionTitleSeperator.defaultProps = {
  hideTop: false,
  hideBot: false,
  h1Override: false
}
SectionTitleSeperator.propTypes = {
  title: PropTypes.string.isRequired,
  hideTop: PropTypes.bool,
  hideBot: PropTypes.bool,
  h1Override: PropTypes.bool
}
export default SectionTitleSeperator
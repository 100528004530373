import React from "react"
import classes from "./gradientButton.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types"

const gradientButton = (props) => {
  library.add(faLongArrowAltRight)

  return (
    <div style={props.style} className={classes.BtnWrapper}>
      <a style={{ borderRadius: props.radius,...props.linkStyle }} className={classes.BtnLink} href={props.url}>
        <span className={classes.BtnText}>{props.btnText}</span>
        {props.icon ? <FontAwesomeIcon
          className={classes.BtnIcon}
          icon={props.icon}
        /> : null}
      </a>
    </div>

  )
}

gradientButton.defaultProps = {
  style: {},
  radius: "0px",
  linkStyle: {}
}

gradientButton.propTypes = {
  url: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  style: PropTypes.object,
  radius: PropTypes.string,
  linkStyle: PropTypes.object
}

export default gradientButton

import React from "react"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import classes from "./ServiceCardBox.module.scss"
import Img from "gatsby-image"
import ServiceCardBoxTitle from "./ServiceCardBoxTitle/serviceCardBoxTitle"
import LocalizedLink from "../../../LocalizedLink"
import { Link } from "gatsby"

const ServiceCardBox = (props) => {

  const cardInner = <Card className={[classes.eqHeight, classes.serviceBoxItemCard].join(" ")}>
    <div className={props.alignCenter ? classes.AlignCenterCenter : null}>

      {props.fluid ? <Img className={classes.serviceBoxImageWrapper} alt={props.title || null} fluid={props.fluid}/>

        : null}
      <Card.Body className={classes.serviceBoxCardBody}>
        {props.title ? <ServiceCardBoxTitle titleStyle={props.titleStyle} title={props.title}/> : null}

        <div className={classes.serviceBoxDescription}>
          {props.description}

        </div>
      </Card.Body>
    </div>

  </Card>

  return (

    <Col className={[classes.serviceBoxItem, props.className, props.grow ? classes.grow: null].join(" ")} lg={props.lg} sm={props.sm} md={props.md}>

      {props.link ?

        props.noLocalization ? <Link to={props.link}>
            {cardInner}
          </Link> :
          <LocalizedLink to={props.link}>
            {cardInner}
          </LocalizedLink>


        : cardInner
      }

    </Col>
  )
}

export default ServiceCardBox
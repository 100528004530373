import React from "react"
import classes from "./gradientText.module.scss"
const gradientText = (props) => {
  return (
   <h5 className={[classes.GradientText,props.className].join(" ")}>
     {props.children}
   </h5>
  )
}

export default gradientText
